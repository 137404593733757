@import "https://fonts.googleapis.com/css?family=PT+Sans:700|Pacifico|Changa+One";@import "https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700italic,300,400italic";@import "https://fonts.googleapis.com/css?family=Montserrat:400,700";body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif
}

code {
    font-family: source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace
}

.header-table {
    color: #fff;
    padding-bottom: 1.5em;
    text-align: center;
    width: 100%
}

.header-table h1 {
    border-bottom: 3px solid #033763;
    color: #afafaf;
    font-family: PT Sans,sans-serif;
    font-size: 29px;
    font-weight: 100;
    margin: .75em 0 .3em
}

.header-table .servertypeinfowrapper {
    border-bottom: 3px solid rgba(30,181,64,.75);
    color: #dadada;
    margin: .75em 0 .3em
}

.header-table .servertypeinfowrapper2 {
    border-bottom: 3px solid #d44f2f;
    color: #dadada;
    margin: .75em 0 .3em
}

.header-table .servertypeinfowrapper3 {
    border-bottom: 3px solid #10a8e4;
    color: #dadada;
    margin: .75em 0 .3em
}

.header-table .servertypename {
    color: rgba(3,187,43,.83)
}

.header-table .servertypename,.header-table .servertypenamets {
    font-family: PT Sans,sans-serif;
    font-size: 29px;
    font-weight: 600;
    padding: 0 6px
}

.header-table .servertypenamets {
    color: #20bfff
}

.header-table .servertypenamerust {
    color: #d44f2f;
    font-family: PT Sans,sans-serif;
    font-size: 29px;
    font-weight: 600;
    padding: 0 6px
}

.header-table .servertypeinfo {
    font-family: PT Sans,sans-serif;
    font-size: 29px;
    font-weight: 100;
    padding: 0 6px
}

.header-table h1:before {
    margin: 20px 0 0 -60px
}

.header-table h1:after {
    margin: 20px 0 0 10px
}

.bodywrapper {
    background: rgba(0,0,0,.63)
}

.main-list {
    display: inline-block;
    margin: 0 15px;
    padding-left: 0
}

.main-list li {
    display: inline-block;
    float: left;
    margin: 0 10px
}

.main-list li p {
    font-size: 1.2em
}

body {
    text-rendering: optimizeLegibility;
    background: #dce1df;
    color: #4f585e;
    font-family: Roboto condensed,sans-serif;
    margin: 0;
    padding: 0
}

a.btn {
    background: #0096a0;
    box-shadow: 0 2px 0 0 rgba(0,0,0,.25);
    cursor: pointer;
    padding: 6px 30px 8px
}

a.btn,a.btnsmall {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    text-decoration: none
}

a.btnsmall {
    background: #23a12d;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.25);
    font-size: 12px;
    padding: 0 12px
}

a.btnsmall-grey {
    background: #333!important
}

.no-touch a.btn:hover {
    background: #00a2ad;
    box-shadow: 0 8px 2px 0 rgba(0,0,0,.075);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
}

.no-touch a.btn:active,a.btn:active,a.btnsmall:active {
    background: #008a93;
    box-shadow: 0 1px 0 0 hsla(0,0%,100%,.25);
    -webkit-transform: translate3d(0,1px,0);
    transform: translate3d(0,1px,0)
}

.headertitle {
    font-weight: 700;
    margin: 12px 0 27px
}

.headertitle,.headertitle-top {
    color: #333;
    font-size: 18px;
    text-align: center;
    text-decoration: underline
}

.headertitle-top {
    margin: 9px 0
}

a.centerbtn {
    border-radius: 4px;
    box-shadow: 0 2px 0 0 rgba(0,0,0,.25);
    color: #fff;
    display: inline-table;
    margin: 0 4px 9px;
    min-width: 120px;
    padding: 6px 0 8px;
    text-decoration: none
}

a.playnowcolor {
    background: rgba(35,161,45,.75)
}

a.playnowcolor:hover {
    background: #23a12d
}

a.playnowcolorpei {
    background: #bf8500
}

a.playnowcolormili {
    background: rgba(255,52,3,.61)
}

a.playnowcolormili:hover {
    background: #bf2e16
}

a.forumscolor {
    background: rgba(0,150,160,.78)
}

a.forumscolor:hover {
    background: #0096a0
}

.top-links-wrapper {
    margin: 0 auto 15px;
    text-align: center
}

div.card {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 23px 4px rgba(0,0,0,.45);
    margin: 6px 3px 9px 10px;
    text-align: left
}

div.card img {
    border-radius: 3px;
    width: 100%
}

div.card div.card-title {
    background: #fff;
    border-bottom: 1px solid #dadada;
    min-height: 66px;
    padding: 6px 16px 0
}

div.card div.card-title a.toggle-info {
    border-radius: 32px;
    height: 32px;
    padding: 0;
    right: 15px;
    top: 14px;
    width: 32px
}

div.card div.card-title a.toggle-info span {
    background: #fff;
    display: block;
    height: 2px;
    top: 16px;
    width: 12px
}

div.card div.card-title a.toggle-info span.left {
    right: 14px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

div.card div.card-title a.toggle-info span.right {
    left: 14px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

div.card div.card-title h2 {
    font-family: PT Sans,sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.05em;
    margin: 0;
    padding: 7px
}

div.card div.card-title h2 small {
    display: block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.025em
}

div.card div.card-description {
    font-size: 12px;
    line-height: 28px;
    padding-left: 2px
}

div.card div.card-actions {
    box-shadow: 0 2px 0 0 rgba(0,0,0,.075);
    padding: 10px 15px 20px;
    text-align: center
}

.alignLeft {
    min-height: 224px;
    margin: 12px 0 0 0;
}

.alignLeft-buttons{
    margin: 0 0 12px 0;
}

div.card div.card-flap {
    background: #d9d9d9;
    width: 100%
}

i.playnowicon {
    font-size: 17px;
    margin-right: 2px;
    top: 1px
}

div.card.show div.card-title a.toggle-info {
    background: #bf0040!important
}

div.card.show div.card-title a.toggle-info span {
    top: 15px
}

div.card.show div.card-title a.toggle-info span.left {
    right: 10px
}

div.card.show div.card-title a.toggle-info span.right {
    left: 10px
}

div.card.show div.card-flap {
    background: #fff
}

div.card.show div.flap1 {
    border-bottom: 3px solid #3d4a3d;
    border-top: 1px dashed #d2d2d2
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    padding: 0 15px
}

.lastcont {
    margin-bottom: 5.2em
}

.card-description ul li {
    font-size: 15px
}

.card-description ul li strong {
    color: #a70000
}

header {
    background: rgba(0,0,0,.57)!important
}

footer {
    background: rgba(46,48,55,.96);
    display: inline-block;
    width: 100%
}

.quater {
    float: left;
    height: 45px;
    padding-bottom: 96px;
    padding-top: 68px;
    text-align: center;
    width: 25%
}

footer p {
    color: #adadad
}

.footer a:link,.footer a:visited,footer a {
    color: #adadad;
    text-decoration: none
}

footer span {
    color: #fff;
    font-size: 20px!important
}

.last-col {
    background-color: rgba(0,0,0,.3)!important
}

.cards,.cards1 {
    text-align: center
}

.last-col a {
    color: rgba(0,0,0,.3)
}

.last-col a:active span,.last-col a:hover span {
    color: #c0392b
}

.last-col span {
    color: #919495;
    margin: 0 5px
}

body {
    background-attachment: fixed;
    background-image: url(https://pandahut.net/wp-content/uploads/2017/04/pandahut-youtube-channel-illustration-2560x1440-low1.jpg);
    background-position: 50%;
    background-repeat: repeat
}

.modal>.header {
    border-bottom: 1px solid gray;
    font-size: 2em;
    padding: 5px;
    text-align: center;
    width: 100%
}

.modal>.content {
    font-size: 1em;
    padding: 10px 5px;
    width: 100%
}

.modal>.actions {
    margin: auto;
    padding: 10px 5px;
    text-align: center;
    width: 100%
}

.modal>.close {
    background: #fff;
    border: 1px solid #cfcece;
    border-radius: 18px;
    cursor: pointer;
    float: center;
    font-size: 24px;
    line-height: 20px;
    padding: 2px 5px;
    right: -10px;
    text-align: center;
    top: -10px
}

.popup-content {
    background-color: #292d3e;
    border: 1px solid #464545;
    color: #fff
}

.actions {
    display: flex;
    margin: auto;
    padding: 10px 5px;
    place-content: center;
    width: 100%
}

.actions,.button {
    text-align: center
}

.serverboxbuttons {
    display: flex;
    justify-content: space-evenly;
    left: -18px;
    list-style-type: none;
    padding-top: 8px;
    position: relative
}

div.card .btn .btnicon {
    color: #fff!important;
    margin: 0 8px 0 0
}

.btn {
    background-color: #2ecc71;
    border-radius: 2px;
    border-width: 0;
    box-shadow: 0 1px 4px rgba(0,0,0,.6);
    color: #ecf0f1;
    cursor: pointer;
    outline: none;
    transition: background-color .3s
}

.btn:focus,.btn:hover {
    background-color: #27ae60
}

.btn>* {
    position: relative
}

.btn span {
    display: block
}

.btn:before {
    background-color: rgba(236,240,241,.3);
    border-radius: 100%;
    content: "";
    left: 50%;
    padding-top: 0;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 0
}

.btn:active:before {
    padding-top: 120%;
    transition: width .2s ease-out,padding-top .2s ease-out;
    width: 120%
}

.card-description .button {
    align-items: center;
    display: flex;
    flex-flow: row;
    font-size: 14px;
    font-weight: 300;
    justify-content: center;
    margin: 6px 0;
    padding: 8px 6px;
}

.card-description .btn:active:before {
    padding-top: 0!important;
    transition: none!important;
    width: 0!important
}

.card-description a {
    min-width: 110px;
    text-decoration: none!important
}

.btn.orange {
    background-color: #33800d
}

.btn.orange:focus,.btn.orange:hover {
    background-color: #276608
}

.btn.red {
    background-color: #e74c3c
}

.btn.red:focus,.btn.red:hover {
    background-color: #c0392b
}

.btn.cyan {
    background-color: #0083aa
}

.btn.cyan:focus,.btn.cyan:hover {
    background-color: #006a8a
}

.btn-server-interact {
    -webkit-font-feature-settings: revert;
    font-feature-settings: revert;
    box-sizing: border-box;
    font-size: 14px;
    font-variant-caps: revert;
    font-weight: 600;
    margin: 4px;
    padding: 6px 16px
}

.servers {
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-size: 45px;
    margin: 0 0 .6em;
    padding: .6em 0!important;
    text-align: center
}

.features {
    background: #fff url(https://pandahut.net/wp-content/themes/zerius/images/shattered.png);
    margin: 25px auto 35px;
    padding: 18px 10px 21px 11px;
    width: 98%
}

.features ul {
    list-style-type: none;
    margin-bottom: 12px
}

.features ul li strong {
    color: #c0392b
}

.features ul li em {
    color: #0096a0
}

.features ul li ul li {
    list-style-type: circle
}

.headline {
    border-bottom: 1px solid #2b4e0b;
    font-size: 20px
}

@media(max-width: 600px) {
    .table>tbody>tr>td,.table>tbody>tr>th,.table>tfoot>tr>td,.table>tfoot>tr>th,.table>thead>tr>td,.table>thead>tr>th {
        padding:.2em!important
    }

    .header-table h1:after,.header-table h1:before {
        width: 20px
    }

    .header-table h1 {
        font-size: 21px
    }

    .header-table h1:before {
        left: 20px;
        margin: 7px 0 0
    }

    .header-table h1:after {
        margin: 7px 0 0;
        right: 20px
    }

    .quater {
        float: none;
        height: 60px;
        margin: 30px 0;
        padding: 10px 0;
        width: 100%
    }

    div.card div.card-title h2 {
        font-size: 18px
    }
}

@media(max-width: 400px) {
    .header-table h1:after,.header-table h1:before {
        width:0
    }
}

.gridnormalstretchy {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-top: 3em
}

@media screen and (max-width: 400px) {
    .gridnormalstretchy {
        width:100%!important
    }
}

@media screen and (max-width: 1400px) {
    .gridnormalstretchy {
        grid-template-columns:repeat(3,1fr)
    }
}

@media screen and (max-width: 1000px) {
    .gridnormalstretchy {
        grid-template-columns:repeat(2,1fr)
    }
}

@media screen and (max-width: 760px) {
    .gridnormalstretchy {
        grid-template-columns:repeat(1,1fr)
    }

    .gridnormalstretchy,.SelectionServerType {
        flex-direction: column!important
    }
}

label {
    color: #fff;
    margin: 0 0 10px;
    padding: 10px
}

label:hover {
    background: #000;
    cursor: pointer
}

.SelectionServerType {
    display: flex!important;
    justify-content: center!important;
    text-align: center
}

.bluelabel {
    background-color: #007bff;
    border: 1px solid #007bff
}

.greenlabel {
    background-color: #28a745;
    border: 1px solid #28a745
}

.yellowlabel {
    background-color: #ff0;
    border: 1px solid #ff0
}
